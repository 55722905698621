<template>
  <a
    :href="`/nto/${marker.id}`"
    class="r-map-balloon pointer"
  >
    <div
      class="r-map-balloon__img"
      v-show="!!marker.photo1 && loadImage"
    >
      <div
        class="backdrop"
        :style="`background-image: url('${marker.photo1}')` "
      />
      <img
        :src="marker.photo1"
        :alt="marker.typeS"
        @load="loadImage = true"
      >
    </div>
    <div class="pa-4">
      <p class="roquefort mb-1">
        {{ marker.typeS }}
      </p>
      <p class="сaprino mozzarella opacity-72">
        {{ marker.address }}
      </p>
      <div
        class="flex align-center mozzarella mt-3"
        v-if="!!Number(marker.contractConclude)"
      >
        <r-icon
          icon="selected"
          fill="rocky"
          class="mr-2"
          size="16"
        />
        Договор до {{ getFormattedDate(marker.dateToS) }}
      </div>
      <a
        :href="`/create-route?toCoords=${marker.lat}, ${marker.lng}`"
        class="flex align-center mt-4 briscola rocky--text pointer"
      >
        <r-icon
          icon="geopoint"
          fill="rocky"
          class="mr-2"
          size="16"
        />
        Построить маршрут
      </a>
    </div>
  </a>
</template>

<script>
import { formattedDate } from '../helpers/utils';

export default {
  name: 'BalloonCard',
  props: {
    marker: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      loadImage: false
    };
  },
  methods: {
    getFormattedDate(date) {
      return formattedDate(date);
    }
  }
};
</script>

<style lang="scss" scoped>
.r-map-balloon {
  width: 200px;
  @media(max-width: 491px) {
    width: 100%;
  }
  &__img {
    width: 100%;
    aspect-ratio: 16/10;
    object-fit: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #E4EDFB;
    overflow: hidden;
  }
  &__info {
    font-family: 'Golos UI';
    padding: 12px 16px 0;
  }
}

</style>
