<template>
  <div class="objects">
    <tag-carousel
      :items="objectTypes"
      @select="onChangeType"
      class="mb-8"
    />
    <loader v-if="isLoading" />
    <div
      v-else
      class="info"
    >
      <div class="objects__list mr-6">
        <card
          v-for="card of filteredObjects"
          :key="card.id"
          :card="card"
        />
      </div>
      <div class="objects__map--wrapper">
        <div
          :class="['objects__map', { 'objects__map--collapsed': isMapCollapsed }]"
          ref="map"
        >
          <rir-map
            v-if="isShowMap"
            show-type
            @click="onMapClick"
            :balloon-component="BalloonCard"
          >
            <div v-if="filteredObjects.length > 0">
              <ymap-marker
                v-for="(marker,index) of filteredObjects"
                :key="`_${marker.id}+${index}`"
                :coords="[marker.lat, marker.lng]"
                :marker-id="`marker_${marker.id}+${index}`"
                :icon="!!Number(marker.contractConclude) ? $markerIcon('mapPinRed') : $markerIcon('mapPin')"
                :options="{
                  hideIconOnBalloonOpen: false,
                  iconColor: !!Number(marker.contractConclude) ? '#E14761' : '#3D75E4'
                }"
                cluster-name="main"
                :balloon-component-props="{
                  marker: marker,
                }"
              />
            </div>
          </rir-map>
          <button
            class="objects__map-collapse-btn feta"
            @click="isMapCollapsed = !isMapCollapsed"
          >
            <span> {{ isMapCollapsed ? 'Развернуть' : 'Свернуть' }} карту </span>
          </button>
        </div>
      </div>
    </div>
    <r-modal
      ref="modal"
      close-icon
      fullscreen
    />
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import Card from '../components/Card';
import { wordMatch } from '../helpers/utils';
import NotFound from '../components/NotFound';
import RirMap from '../components/RirMap';
import BalloonCard from '../components/BalloonCard';
import TagCarousel from '../components/TagCarousel';

export default {
  name: 'ObjectList',
  components: {
    Loader,
    Card,
    NotFound,
    RirMap,
    TagCarousel
  },
  data() {
    return {
      isMapCollapsed: false,
      selectedSortType: 'ALL',
      timeoutId: null,
      isShowMap: false,
      selectedMarkerId: null,
      objectTypes: [
        {
          id: 'ALL',
          title: 'Все категории',
          active: true
        },
        {
          id: '0',
          title: 'Занятые',
          active: false
        },
        {
          id: '1',
          title: 'Свободные',
          active: false
        }
      ]
    };
  },
  computed: {
    BalloonCard() {
      return BalloonCard;
    },
    isLoading() {
      return this.$store.state.isLoading;
    },
    filteredObjects() {
      let list = this.$store.state.allObjects;

      // Если заполнена строка поиска
      if (this.searchText) {
        list = list.filter(
          el => wordMatch(el?.typeS, this.searchText) <= 0.5
            || wordMatch(el?.address, this.searchText) <= 0.5
        );
      }
      if (this.selectedSortType !== 'ALL') {
        list = list.filter(el => el.contractConclude === this.selectedSortType);
      }
      return list;
    },
    countObjects() {
      return this.filteredObjects.length;
    },
    search: {
      get() {
        return this.searchText;
      },
      set(val) {
        if (this.timeoutId) clearTimeout(this.timeoutId);
        this.timeoutId = setTimeout(() => {
          this.searchText = typeof val === 'string' ? val.toLowerCase() : null;
        }, 420);
      }
    }
  },
  activated() {
    this.isShowMap = true;
  },
  deactivated() {
    this.isShowMap = false;
  },
  async created() {
    await this.$store.dispatch('getAllObjects');
  },
  methods: {
    onChangeType(type) {
      this.selectedSortType = type.id;
    },
    onMarkerClick(id) {
      this.selectedMarkerId = id;
    },
    onMapClick() {
      this.selectedMarkerId = null;
    },
    onBalloonClick() {
      if (!this.selectedMarkerId) return;

      this.$router.push({ name: 'Edit', params: { id: this.selectedMarkerId } });
    },
    // Обрабочики для Balloon'a карты
    bindListener() {
      if (!this.selectedMarkerId) return;

      const balloon = this.$refs.map.querySelector(
        `.rir-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    },
    unbindListener() {
      if (!this.selectedMarkerId) return;
      const balloon = this.$refs.map.querySelector(
        `.rir-map-balloon[data-id="${this.selectedMarkerId}"]`
      );

      balloon.addEventListener('click', this.onBalloonClick);
    }
  }
};
</script>

<style lang="scss" scoped>
.info {
  align-items:flex-start;
  justify-content: space-between;
  display: flex;
}
.objects {
  &__list {
    width: 41.7%;
  }

  &__map--wrapper {
    position: sticky;
    position: -webkit-sticky;
    top: 32px;
    height: 648px;
    width: calc(58.3% - 32px);
    margin-left: 32px;
  }

  &__map {
    position: relative;
    display: flex;
    height: 100%;
    width: 100%;
    background-color: #ccc;
    border-radius: 24px;
    position: relative;
    transition: 0.4s all;
  }

  &__map-collapse-btn {
    display: none;
  }
}

.balloon-staff {
  display: grid;
  grid-template-columns: auto 1fr;
}
@media screen and (max-width: 1023px) {
  .info {
    align-items: unset;
    justify-content: unset;
    display: unset;
    max-width: 100%;
    flex-direction: column-reverse;
    display: flex;
    justify-content: space-between;
  }
  .objects {
    max-width: 100%;
    &__list {
      width: 100%;
    }

    &__map--wrapper {
      position: relative;
      top: unset;
      background-color: #fff;
      height: unset;
      padding: 0;
      margin-left: unset;
      width: 100%;
    }

    &__map {
      height: 360px;
      margin-bottom: 44px;
    }

    &__map--collapsed {
      height: 112px;
    }

    &__map-collapse-btn {
      height: 40px;
      width: 174px;
      border-radius: 8px;
      padding: 10px 16px;
      position: absolute;
      bottom: -20px;
      left: calc(50% - 87px);
      background-color: var(--rir-arrival);
      cursor: pointer;
      display: block;

      :first-child {
        color: var(--rir-godfather);
        border-bottom: 2px dotted #214eb052;
      }
    }
  }
}

@media screen and (min-width: 1024px) {
  .objects {
    &__input-search {
      display: none;
      // position: absolute;
      // z-index: 10;
      // background-color: #fff;
      // box-shadow: 0 4rem 16rem rgb(4 21 62 / 16%);
      // top: 16px;
      // left: 16px;
    }
  }
}

@media screen and (max-width: 599px) {
  .objects {
    &__map--collapsed {
      height: 144px;
    }
  }
}

.add {
  margin-bottom: 32px;
  height: 80px;
  margin-top: 12px;
  background: #F9F6FE;
  border-radius: 16px;

  &__text {
    width: 50%;
    margin-left: 24px;
    display: table;
    float: left;
  }

  &__bottom {
    cursor: pointer;
    margin-top: 6px;
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #9954F2;
  }

  &__title {
    margin-top: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    color: #21043E;
  }

  &__image {
    float: right;
    margin-right: 24px;
    margin-top: -32px;
  }
}

@media screen and (max-width: 599px) {
  .add {
    &__text {
      width: 100%;
    }

    &__image {
      display: none;
    }
  }
}
</style>
