<template>
  <div>
    <p
      class="inline-flex align-center text-rocky sulguni hover-link mb-6"
      @click.prevent="routeBack"
    >
      <r-icon
        icon="arrow-left"
        class="mr-2"
      />
      Назад
    </p>
    <loader v-if="isLoading" />
    <page404 v-else-if="notFound" />
    <div
      v-else
      class="info flex justify-between"
    >
      <div class="info__description">
        <div class="flex align-center mb-2">
          <h1 class="taleggio">
            {{ formData.typeS }}
          </h1>
          <div @click="favoriteFnc()">
            <r-icon
              class="ml-3 pointer"
              icon="favorite-add"
              size="20"
              fill="rocky"
              v-if="userId && !favorite"
            />
            <r-icon
              class="ml-3 pointer"
              icon="favorite-add-active"
              size="20"
              fill="rocky"
              v-if="userId && favorite"
            />
          </div>
        </div>
        <p class="info__address feta mt-1">
          {{ formData.address }}
        </p>
        <a
          :href="`/create-route?toCoords=${formData.lat}, ${formData.lng}`"
          class="flex align-center mt-2 sulguni text-rocky mb-8"
        >
          <r-icon
            class="mr-3"
            icon="geopoint"
            size="16"
          />
          Построить маршрут
        </a>
        <div
          class="info__mob-map-cont"
          ref="mobMapCont"
        />
        <!--<rir-gallery
          :files="[
            {
              url: formData.photo1,
              filename: formData.photo1 || 'Без имени'
            }
          ]"
          :show-zoom="true"
          :show-title="false"
          :open-modal="true"
          class="mb-8"
        />-->
        <div
          class="info__image mb-8"
          v-if="formData.photo1"
          v-show="imageIsLoaded"
        >
          <div
            class="backdrop"
            :style="`background-image: url('${formData.photo1}')` "
          />
          <img
            @load="loadImage"
            :src="formData.photo1"
            :alt="formData.typeS"
          >
        </div>
        <p class="feta opacity-48">
          Специализация
        </p>
        <p class="parmigiano mt-2">
          {{ formData.spec }}
        </p>
        <div
          v-if="formData.description"
          class="mt-8"
        >
          <p class="feta opacity-48">
            Описание
          </p>
          <p class="parmigiano mt-2">
            {{ formData.description }}
          </p>
        </div>
        <div class="flex mt-8">
          <div
            class="mr-8"
            v-if="!!Number(formData.contractConclude)"
          >
            <p class="feta opacity-48">
              Договор
            </p>
            <p class="parmigiano mt-2">
              {{ formData.dateToS ? ('Договор до ' + getFormattedDate(formData.dateToS)) : '—' }}
            </p>
          </div>
          <div>
            <p class="feta opacity-48">
              Площадь
            </p>
            <p class="parmigiano mt-2">
              {{ formData.areaS }} м2
            </p>
          </div>
        </div>
        <div
          class="flex align-start mt-7 flex-wrap"
          v-if="formData.phones && formData.phones.length"
        >
          <p
            v-for="(phone, index) of formData.phones"
            :key="phone + index"
            @click.prevent.stop="generatePhoneLink(phone)"
            class="flex align-center sulguni text-rocky pointer "
          >
            <r-icon
              v-if="index === 0"
              class="mr-2"
              icon="telephone"
            />
            <span class="hover-link">{{ phone }}</span>
          </p>
        </div>
        <div
          class="flex align-start mt-7 flex-wrap"
          v-if="formData.emails && formData.emails.length"
        >
          <a
            v-for="(email, index) of formData.emails"
            :key="email + index"
            :href="`mailto:${email}`"
            class="flex align-center sulguni text-rocky pointer"
          >
            <r-icon
              v-if="index === 0"
              class="mr-2"
              icon="mail"
            />
            <span class="hover-link">{{ email }}</span>
          </a>
        </div>
        <div class="mt-6">
          <p class="feta opacity-48">
            Ближайшие остановки
          </p>
          <div
            class="parmigiano mt-2"
            v-for="station of busStation"
            :key="station.name"
          >
            {{ station.name }}
            <div class="transport">
              <div
                v-for="(route, index) of station.routes"
                :key="index"
                v-if="route?.vehicleTypes[0]"
                class="transport_card sulguni"
                :style="`background-color: var(--rir-${busColorScheme[route.vehicleTypes[0]]});`"
              >
                <r-icon
                  fill="amelie"
                  class="mr-2"
                  :icon="route?.vehicleTypes[0]"
                />
                {{ index }}
              </div>
            </div>
          </div>
        </div>

        <button
          transparent
          class="text-rocky mt-4 hover-link"
          @click="stationVisibilityManage"
        >
          <r-icon
            class="mr-2"
            icon="location"
          />
          {{ stationIsShow ? 'Скрыть с карты' : 'Показать на карте' }}
        </button>

        <h2 class="taleggio mb-3 mt-8">
          Поделиться
        </h2>
        <share />
      </div>
      <div
        class="info__pc-map-cont"
        ref="pcMapCont"
      >
        <div
          :class="isMap ? 'info__map info__map--collapsed': 'info__map' "
          ref="map"
        >
          <rir-map
            v-if="isMapReady"
            :center="[formData.lat, formData.lng] || $cityCenter"
            @onInit="initMap"
          >
            <ymap-marker
              :coords="[formData.lat, formData.lng]"
              :marker-id="formData.id"
              :icon="!!Number(formData.contractConclude) ? $markerIcon('mapPinRed') : $markerIcon('mapPin')"
              :options="{
                hasBalloon:false
              }"
            />
            <template v-if="stationIsShow">
              <div
                v-for="(station) of busStation"
                :key="station.name"
              >
                <ymap-marker
                  v-for="(coords, index) of station.coordinates"
                  :key="'station'+index"
                  :hint-content="'Остановка ' + station.name"
                  :coords="coords"
                  :marker-id="'station'+index"
                  :icon="{
                    layout: 'default#imageWithContent',
                    imageHref: 'data:image/svg+xml;charset=UTF-8,%3csvg width=\'24\' height=\'24\' viewBox=\'0 0 24 24\' fill=\'none\' xmlns=\'http://www.w3.org/2000/svg\'%3e%3crect width=\'24\' height=\'24\' rx=\'4\' fill=\'%236F7A90\'/%3e%3cpath fill-rule=\'evenodd\' clip-rule=\'evenodd\' d=\'M5.4375 17.25C4.86562 16.8447 4.5 16.2626 4.5 15.6142V7.44737C4.5 4.86842 7.85625 4.5 12 4.5C16.1438 4.5 19.5 4.86842 19.5 7.44737V15.6142C19.5 16.2626 19.1344 16.8447 18.5625 17.25V18.3947C18.5625 19.0063 17.925 19.5 17.1562 19.5C16.3781 19.5 15.75 18.9989 15.75 18.3947V17.8247H8.25V18.3947C8.25 19.0063 7.62188 19.5 6.84375 19.5C6.06562 19.5 5.4375 19.0063 5.4375 18.3947V17.25ZM17 11.4977C17.5523 11.4977 18 11.0499 18 10.4977V7.59766C18 7.04537 17.5523 6.59766 17 6.59766H7C6.44772 6.59766 6 7.04537 6 7.59766V10.4977C6 11.0499 6.44772 11.4977 7 11.4977H17ZM6 15C6 14.1716 6.67157 13.5 7.5 13.5C8.32843 13.5 9 14.1716 9 15C9 15.8284 8.32843 16.5 7.5 16.5C6.67157 16.5 6 15.8284 6 15ZM16.5 13.5C15.6716 13.5 15 14.1716 15 15C15 15.8284 15.6716 16.5 16.5 16.5C17.3284 16.5 18 15.8284 18 15C18 14.1716 17.3284 13.5 16.5 13.5Z\' fill=\'white\'/%3e%3c/svg%3e',
                    imageSize: [20, 20],
                    imageOffset: [0, 0],
                  }"
                  :settings="{
                    imageSize:[10,10]

                  }"
                />
              </div>
            </template>
          </rir-map>
          <button
            class="info__map-collapse-btn feta"
            @click="isMap = !isMap"
          >
            <span> {{ isMap ? 'Развернуть' : 'Свернуть' }} карту </span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from '@/components/Loader.vue';
import Api from '../api/Api';
import { formattedDate } from '../helpers/utils';
import Share from '../components/Share';
import rirMap from '../components/RirMap';
import Page404 from './Page404';

export default {
  name: 'ObjectInfo',
  components: {
    Loader,
    Share,
    rirMap,
    Page404
  },
  data() {
    return {
      isMap: true,
      isLoading: true,
      isMapReady: false,
      favorite: false,
      userId: false,
      busStation: [],
      busColorScheme: {
        bus: 'rocky',
        'public-transport-trolley': 'matrix',
        minibus: 'gentlemen',
        tram: 'lebowski'
      },
      stationIsShow: false,
      map: null,
      formData: {
        photo1: null,
        typeS: null,
        address: null,
        lat: null,
        lng: null,
        areaS: null,
        dateToS: null,
        places: null,
        spec: null,
        description: null,
        contractConclude: false,
        contractDateEnd: null,
        phones: [],
        emails: [],
        hidden: false
      },
      imageIsLoaded: false,
      notFound: false
    };
  },
  activated() {
    this.setData();
    this.isMapReady = true;
  },
  deactivated() {
    // this.intervalID && clearInterval(this.intervalID);
    this.isMapReady = false;
  },
  methods: {
    loadImage() {
      this.imageIsLoaded = true;
    },
    initMap(e) {
      this.map = e;
    },
    stationVisibilityManage() {
      this.stationIsShow = !this.stationIsShow;
      if (this.map) {
        const coords = this.busStation.map(el => el.coordinates);
        this.map.setBounds(window.ymaps.util.bounds.fromPoints(
          coords[0]
        ), {
          checkZoomRange: true,
          zoomMargin: 10,
          duration: 300
        });
      }
    },
    generatePhoneLink(phone) {
      phone = phone.replace(/\s|\(|\)|\-/g, '');
      document.location.href = `tel:${phone}`;
    },
    getFormattedDate(date) {
      return formattedDate(date);
    },
    favoriteFnc() {
      new Api().operateFavorite(this.$route.params.id)
        .then(result => {
          this.favorite = result.isSet;
        });
    },
    routeBack() {
      this.$router.go(-1);
    },
    async setData() {
      this.isLoading = true;
      const api = new Api();
      const res = await api.getUserInfo();
      if (res?.userId > 0) {
        let favorite = await api.getFavorite();
        favorite = favorite.fav.filter(el => ((el.id === this.$route.params.id)));
        if (favorite.length > 0) {
          this.userId = true;
          this.favorite = true;
        } else {
          this.userId = true;
        }
      }
      await this.$store.dispatch('getObjectById', this.$route.params.id).then(res => {
        if (res.all?.length > 0) {
          this.formData = { ...this.formData, ...res.all[0] };
          this.formData.contractConclude = !!Number(this.formData.contractConclude);
          this.formData.hidden = !!Number(this.formData.hidden);
          api.getBusStop(this.formData.lat, this.formData.lng).then(bus => {
            const busStation = bus.reduce((acc, cur) => {
              acc[cur.name] = acc[cur.name] || {
                name: cur.name,
                routes: {},
                coordinates: []
              };
              acc[cur.name].coordinates.push([cur.lat, cur.lng]);
              acc[cur.name].routes[Object.keys(cur.routes)] = cur.routes[Object.keys(cur.routes)];
              return acc;
            }, {});
            this.busStation = Object.values(busStation);
          });
        } else {
          this.notFound = true;
        }
      }).finally(() => {
        this.isLoading = false;
      });
    }
  }
};
</script>

<style scoped lang="scss">
.hover-link{
  transition: .2s;
  &:hover{
    cursor: pointer;
    opacity: .8!important;
  }
}

.open_image {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  //width: 100%;

  &:before {
    content: '';
    background: linear-gradient(360deg, rgba(4, 21, 62, 0.8) 0%, rgba(4, 21, 62, 0) 100%);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    transition: 0.3s ease-in-out;
    opacity: 0;
  }

  img {
    z-index: -1;
  }

  .rir-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -40%);
    opacity: 0;
    pointer-events: none;
    transition: 0.2s ease-in-out;
  }

  &:hover {
    &:before {
      transform: translateY(0);
      opacity: 1;
    }

    .rir-icon {
      transform: translate(-50%, -50%);
      opacity: 1;
    }
  }
}

.med {
  font-family: Golos UI;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #04153e;
  opacity: 0.48;
}

.info {
  &__image{
    width: 100%;
    height: 100%;
    max-height: 235px;
    overflow: hidden;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    z-index: 1;
    .backdrop{
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      filter: blur(10px);
      z-index: -1;
    }
    img{
      max-width: 100%;
      max-height: 100%;
    }
  }
  &__name {
    text-decoration: underline;
  }

  &__description {
    text-align: left;
    width: 41.7%;
  }

  &__gallery {
    height: 234px;
  }

  &__work-hours {
    color: var(--rir-titanic);
  }

  &__transport-title {
    opacity: 0.48;
  }

  &__pc-map-cont {
    padding-left: 32px;
    width: 58.3%;
  }

  &__img {
    aspect-ratio: 16/10;
    object-fit: cover;
    // height: 234px;
    // width: 375px;
    width: 100%;
  }

  &__mob-map-cont {
    display: none;
  }

  &__map {
    position: sticky;
    position: -webkit-sticky;
    top: 32px;
    max-width: 100%;
    width: 100%;
    height: 648px;
    background-color: #ccc;
    border-radius: 24px;
    transition: 0.4s all;
  }

  &__show-on-map {
    display: flex;
    align-items: center;
    background: none;
    margin-top: 18px;
    color: var(--rir-rocky);

    &:hover {
      cursor: pointer;
    }
  }

  &__map-collapse-btn {
    height: 40px;
    width: 174px;
    border-radius: 8px;
    padding: 10px 16px;
    position: absolute;
    bottom: -20px;
    left: calc(50% - 87px);
    background-color: var(--rir-arrival);
    cursor: pointer;
    display: none;

    :first-child {
      color: var(--rir-godfather);
      border-bottom: 2px dotted #214eb052;
    }
  }

  &__institution-link {
    &:hover {
      * {
        color: var(--rir-godfather);
      }
    }
  }
}

.ext-link {
  border-bottom: 2px solid var(--rir-alien);
}

::v-deep .rir-modal__content {
  display: grid;
  align-items: center;
}

@media screen and (max-width: 1023px) {
  .info {
    &__description {
      width: 100%;
    }

    &__map {
      position: relative;
      top: unset;
      margin-bottom: 44px;
      height: 447.31px;
    }

    &__map--collapsed {
      height: 112px;
    }

    &__mob-map-cont {
      display: block;
    }

    &__pc-map-cont {
      display: none;
    }

    &__map-collapse-btn {
      display: block;
    }
  }
}

@media screen and (max-width: 599px) {
  .info {
    &__map--collapsed {
      height: 144px;
    }
  }
}

.point {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #3D75E4;
  margin-bottom: 34px;
}
.grid{
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fill, 150px);
  grid-gap: 16px;
  p{
    white-space: nowrap;
  }
}
.flex-wrap{
  display: flex;
  flex-wrap: wrap;
  p,a{
    white-space: nowrap;
    margin: 5px 0;
    &:not(:last-child){
      margin-right: 16px;
    }
  }
}
.transport{
  &_card{
    display: inline-flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    padding: 6px 8px;
    color: var(--rir-amelie);
    border-radius: 8px;
    margin: 4px 0;
    &:not(:last-child){
      margin-right: 6px;
    }
  }
}
</style>
