import Vue from 'vue';
import Vuex from 'vuex';
import Api from '@/api/Api';

const api = new Api();
Vue.use(Vuex);

export default new Vuex.Store({
  state: () => ({
    allObjects: [],
    isLoading: true
  }),
  mutations: {
    setAllObjects(state, res) {
      state.allObjects = res;
    },
    setLoading(state, bool) {
      state.isLoading = bool;
    }
  },
  getters: {},
  actions: {
    async getAllObjects(ctx) {
      ctx.commit('setLoading', true);
      const res = await api.getAllObjects();
      ctx.commit('setAllObjects', res.all);
      ctx.commit('setLoading', false);
      return res;
    },
    async getObjectById(ctx, id) {
      const res = await api.getObjectById(id);
      return res;
    }
  },
  modules: {}
});
