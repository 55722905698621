import ApiService from './ApiService';

export default class Api extends ApiService {
  async getUserInfo() {
    const { data } = await this._axios.get('/nginxApi.php?get=user');
    return data;
  }

  async getUser() {
    const { data } = await this._axios.get('/auth/rest/user');

    return data;
  }

  async getFavorite() {
    const { data } = await this._axios.post('/ajax.php?action=operateFavorite', {
      currentMainMenuNumber: 38
    });

    return data;
  }

  async operateFavorite(id) {
    const { data } = await this._axios.post('/ajax.php?action=operateFavorite', {
      id,
      currentMainMenuNumber: 38
    });

    return data;
  }

  async getAllObjects() {
    const { data } = await this._axios.post('/ajax.php?action=getRegions', { currentMainMenuNumber: 55 });
    return data;
  }

  async getObjectById(id) {
    const { data } = await this._axios.post('/ajax.php?action=getRegions', { id, currentMainMenuNumber: 55 });
    return data;
  }

  async getBusStop(lat, lng) {
    const { data } = await this._axios.get(`/transportProxy.php?url=stations&lat=${lat}&lng=${lng}&radius=300&noACAO=1`);
    return data;
  }
}
