<template>
  <div
    @click="link(card.id)"
    class="card pointer"
  >
    <div class="flex">
      <div class="card__image mr-3">
        <img
          v-show="!!card.photo1 && loadImage"
          :src="card.photo1"
          :alt="card.typeS"
          @load="loadImage = true"
        >
        <r-icon
          v-show="!(!!card.photo1 && loadImage)"
          size="32"
          icon="trade-objects"
          fill="alien"
        />
      </div>
      <div>
        <p class="sulguni card__title">
          {{ card.typeS }}
        </p>
        <p class="mozzarella opacity-72 mt-1 card__address">
          {{ card.address }}
        </p>
        <template v-if="card.phones && card.phones.length">
          <p
            v-for="(phone, index) of card.phones"
            :key="phone + index"
            @click.prevent.stop="generatePhoneLink(phone)"
            class="flex align-center briscola text-rocky mt-2 pointer"
          >
            <r-icon
              class="mr-2"
              icon="telephone"
              size="16"
            />
            {{ phone }}
          </p>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { formattedDate } from '../helpers/utils';

export default {
  name: 'Card',
  components: {},
  props: {
    card: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isShowPopover: false,
      loadImage: false
    };
  },
  methods: {
    link(id) {
      const r = this.$router.resolve({
        name: 'info',
        params: { id }
      });
      window.location.assign(r.href);
    },
    generatePhoneLink(phone) {
      phone = phone.replace(/\s|\(|\)|\-/g, '');
      document.location.href = `tel:${phone}`;
    },
    getFormattedDate(date) {
      return formattedDate(date);
    }
  }
};
</script>

<style lang="scss" scoped>
.card {
  position: relative;
  display: flex;
  &:not(:last-child){
    margin-bottom: 24px;
  }
  .pointer {
    cursor: pointer;
  }

  &__image {
    width: 56px;
    height: 56px;
    border-radius: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    background: #E4EDFB;
    flex-shrink: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &:hover {
    .card__title {
      color: var(--rir-godfather);
    }
    .card__address {
      color: var(--rir-godfather);
      opacity: 0.72;
    }
  }
}

</style>
